import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import Partners from "@/components/Home/Partners";
import Breadcrumb from "@/components/Common/Breadcrumb";
import { useLocation } from "@reach/router";
import News from "@/components/News";
import BlogLayout from "@/components/Layout/BlogLayout";
import { useBlog } from "@/context/BlogContext";

const Search = ({ params }: any) => {
  const { setSearch, clearStorage } = useBlog();
  const { search } = params;

  useEffect(() => {
    ["author", "category", "tag"].forEach((el) => {
      clearStorage(el);
    });
    setSearch(search);
    window.localStorage.setItem("search", search);
  }, [search]);

  return (
    <Layout>
      <SEO title="News" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Latest Posts" pathName="Search" />
      <BlogLayout>
        <News />
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default Search;
